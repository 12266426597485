footer{
	padding: 30px 0;
	background: url('../images/pattern.png');
	background-size: 200px;
}

#raptor-footer-copyright{
	margin-top: 20px;
}

#raptor-footer-copyright p{
	margin-bottom: 0;
}

#raptor-footer-copyright p:last-child{
	margin: 1.75rem 0 0;
}

.raptor-footer-contact{
	margin-bottom: 20px;
}

.raptor-footer-contact:last-child{
	margin-bottom: 0;
}

.raptor-footer-contact a{
	color: $white;
}

.raptor-footer-contact p{
	margin: 0;
}

.raptor-footer-contact i{
	margin-right: 10px;
}

#raptor-footer-address{
	border-left: 1px solid $brand-primary;
	padding-left: 30px;
	
	strong{
		text-transform: uppercase;
	}
	
	p{
		margin-bottom: 20px;
		
		&:last-child{
			margin: 0;
		}
	}
}

/*Media Queries*/

@media (max-width: 991px) {
	footer .col-md-6{
		margin-bottom: 20px;
	}
	footer .col-md-6:last-child{
		margin-bottom: 0;
	}
	footer .col-md-5{
		margin-bottom: 20px;
	}
}