header{
	background: url('../images/pattern.png');
	background-size: 200px;
}

#raptor-header-brand{
	padding: 30px 0;
	text-align: center;
}

#raptor-header-nav{
	border-top: 1px solid $brand-primary;
}

#raptor-header-brand-logo{
	width: 350px;
}

/*Navigation*/

.nav{
	text-align: center;
}

.nav>li>a{
	padding: 30px;
	background: none;
}

.nav>li>a:hover{
	background: none;
	color: $brand-primary;
	transition: all ease 0.5s;
}

.nav>li, .nav>li>a{
	display: inline-block;
	font-size: 20px;
	color: $white;
}

/*Media Queries*/

@media (max-width: 991px) {
	.nav>li>a{
		padding: 30px 20px;
	}
}

@media (max-width: 768px) {
	#raptor-header-brand-logo{
		width: 250px;
	}
}